const validate = (name, email, phone, message) => {
  const nameRegex = /[a-zA-Z]+/g
  const emailRegex = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/
  const linkRegex = /(?:https?|ftp):\/\/[\n\S]+/g
  const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g

  const check = {
    name: name.match(nameRegex) ? true : false,
    email: email.match(emailRegex) ? true : false,
    phone: phone.match(phoneRegex) ? true : false,
    message: message.length >= 10 && message.match(linkRegex) ? false : true,
  }
  const errors = {
    name: check.name
      ? ""
      : "Name should not contain numbers or symbols unless you are C3PO or R2D2",
    email: check.email ? "" : "Please provide a valid email",
    phone: check.phone ? "" : "Please provide a valid phone number",
    message: check.message ? "" : "Message too short or contains a link",
  }

  return {
    status: check.name && check.email && check.phone && check.message,
    errors: errors,
  }
}

export default validate
