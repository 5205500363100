import React, { Component } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import "./ContactUs.css"
import { config } from "@fortawesome/fontawesome-svg-core"
import validate from "../index/validation"
import { Container, Row, Col, Card, Form } from "react-bootstrap"
import map from "../../images/map.png"
config.autoAddCss = false

const recaptchaRef = React.createRef()

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      message: "",
      errors: {},
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  handleSubmit(event) {
    event.preventDefault()
    recaptchaRef.current.execute()
    const validation = validate(
      this.state.name,
      this.state.email,
      this.state.phone,
      this.state.message
    )
    if (validation.status === false) {
      this.setState({
        errors: {
          name: validation.errors.name,
          email: validation.errors.email,
          message: validation.errors.message,
        },
      })
    } else {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...this.state }),
      })
        .then(() => alert("Form succesfully submitted!"))
        .catch(error => alert(error))
      this.setState({
        name: "",
        email: "",
        message: "",
        errors: {},
      })
    }
  }

  render() {
    return (
      <div>
        <Row>
          <Col sm={12} lg={6}>
            <Container className="pl-lg-5 pt-lg-3">
              <h2 className="pl-3 pt-2">Contact Us</h2>
              <Col lg={9} className="aboutUsPageText">
                <p>
                  We develop innovative digital solutions for occupational
                  safety industry that helps companies tackle technical
                  challenges in the digital age.
                </p>
                <br />
                <p>
                  Simple Report was founded in 2016 in Victoria, Canada with a
                  vision to automate OHS practices and improve safety compliance
                  in a variety of industries.
                </p>
              </Col>
            </Container>
          </Col>
          <Col sm={12} lg={4} className="pt-5">
            <div>
              <Col>
                <form
                  onSubmit={this.handleSubmit}
                  className="contactUsForm container pl-5"
                  name="contact"
                  data-netlify="true"
                >
                  {this.state.errors.name && (
                    <span className="error">{this.state.errors.name}</span>
                  )}
                  <Form.Label>Name</Form.Label>
                  <input
                    type="text"
                    required
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    className="textField1 form-control"
                    placeholder="Joe Smith"
                  />
                  {this.state.errors.email && (
                    <span className="error">{this.state.errors.email}</span>
                  )}
                  <Form.Label>E-Mail</Form.Label>
                  <input
                    type="email"
                    required
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    className="textField1 form-control"
                    placeholder="joe.smith@example.com"
                  />
                  {this.state.errors.name && (
                    <span className="error">{this.state.errors.phone}</span>
                  )}
                  <Form.Label>Phone</Form.Label>
                  <input
                    type="text"
                    required
                    name="phone"
                    value={this.state.phone}
                    onChange={this.handleChange}
                    className="textField1 form-control"
                    placeholder="(604) 555-1234"
                  />
                  {this.state.errors.message && (
                    <div className="error">{this.state.errors.message}</div>
                  )}
                  <Form.Label>Message</Form.Label>
                  <textarea
                    type="text"
                    required
                    name="message"
                    value={this.state.message}
                    onChange={this.handleChange}
                    className="textArea form-control"
                    placeholder="Enter message here"
                  />
                  <br />
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6Lc2wJoUAAAAAKfixNSMW499bpgqspdV1bVWojok"
                  />
                  <button
                    type="submit"
                    value="Submit"
                    className="submitButton d-flex align-items-center"
                  >
                    <span className="submitText">SUBMIT</span>{" "}
                    <div className="ml-2 square mt-1" />
                  </button>
                </form>
              </Col>
            </div>
          </Col>
        </Row>
        <Row className="pt-5 pb-5 mapRow">
          <Col sm={12} lg={8} className="pr-0 pl-0">
            <Card.Img src={map} />
          </Col>
          <Col sm={12} lg={4} className="pr-0 pl-0 contactUsInfo">
            <Container className="pl-lg-5 pt-lg-3 margin-auto">
              <Col lg={9} className="aboutUsPageText">
                <h6>Simple Report Canada</h6>
                <br />
                <p>
                  2174 Cadboro Bay, Rd Victoria, British Columbia Canada V8R 5H1
                </p>
              </Col>
            </Container>
          </Col>
        </Row>
      </div>
    )
  }
}
