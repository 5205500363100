import React from "react"
import Layout from "../components/index/layout"
import SEO from "../components/index/seo"
import Form from "../components/contact-us/Form"
import Header from "../components/index/Header"

import "../components/contact-us/ContactUs.css"
const ContactUs = () => (
  <Layout>
    <Header />
    <SEO
      title="Contact Us"
      keywords={[
        `SimpleReport`,
        `SimpleReport.ca`,
        `reporting`,
        `report`,
        `safety`,
        `OHS`,
        `OSHA`,
        `OHS-reporting`,
        `OSHA-reporting`,
        `invoices`,
        `invoicing`,
        `ERP`,
        `Occupational Health and Safety`,
        `EHS`,
        `Environmental Health and Safety`,
      ]}
    />
    <Form />
  </Layout>
)

export default ContactUs
